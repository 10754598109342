import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Moment } from "moment";
import { InputMastersServiceProxy, VersionDto } from '@shared/service-proxies/service-proxies';
import {
    MomentBsDatePickerComponent
} from '@app/shared/Components/moment-bs-datepicker/moment-bs-datepicker.component';
import {AvailabilityModels} from "@app/main/availability/availability.models";

@Component({
    selector: 'app-advanced-filter-form',
    templateUrl: './advanced-filter-form.component.html',
    styleUrls: ['./advanced-filter-form.component.css']
})
export class AdvancedFilterFormComponent implements OnInit {
    @ViewChild("operationDateIpnput") operationDateIpnput: MomentBsDatePickerComponent;
    @Input() operationDate: Moment;
    @Input() inputMasterId: number;
    @Input() versions: VersionDto[];
    @Input() versionsLoading: boolean;
    @Input() filtersButtonDisabled: boolean;
    @Input() showVersion: boolean;
    @Input() hasInputMaster: boolean = true;
    @Input() hasOperationDate: boolean = true;

    @Output() operationDateChange = new EventEmitter<Moment>();
    @Output() inputMasterIdChange = new EventEmitter<number>();
    @Output() reset = new EventEmitter<void>();
    @Output() filterChange = new EventEmitter<void>();

    showAdvancedFilters: boolean;
    versionInfo: string;

    constructor(
        private readonly _inputMasterServiceProxy: InputMastersServiceProxy
    ) {
    }

    ngOnInit(): void {
        if (this.showVersion === undefined) {
            this.showVersion = true;
        }
    }

    onOperationDateChange($event: Moment) {
        this.operationDateChange.emit($event);
    }
    onOperationDateAdd() {
        this.operationDateIpnput.Date = this.operationDateIpnput.Date.add(1,'d')
        this.onOperationDateChange(this.operationDateIpnput.Date);
    }
    onOperationDateSubtract() {
        this.operationDateIpnput.Date = this.operationDateIpnput.Date.subtract(1,'d')
        this.onOperationDateChange(this.operationDateIpnput.Date);
    }

    onToggleAdvancedFilters() {
        this.showAdvancedFilters = !this.showAdvancedFilters;
    }

    onResetButtonClick() {
        this.reset.emit();
    }

    onApplyFilterButtonClick() {
        this.filterChange.emit();
    }

    onVersionChange($event: any) {
        this.inputMasterIdChange.emit($event);

        this._inputMasterServiceProxy.getVersionInfo($event).subscribe(versionInfo => {
            this.versionInfo = versionInfo.creatorName + ' - ' + versionInfo.operationDate.format("YYYY-MM-DD HH:mm:ss");
        })
    }
}
